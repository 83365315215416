<template>
  <div class="panel panel-default">
    <div class="row panel-body details_container" >
      <DetailsCheckbox class="col-lg-4 col-sm-6 col-xs-12"
          :field="{name: 'noa_compliant', label: 'NOA compliant'}"
          :val="data.noa_compliant"
          @save="save"
      />
      <DetailsCheckbox class="col-lg-4 col-sm-6 col-xs-12"
          :field="{name: 'straight_pitched_conforming', label: 'Stock Straight/Pitched Conforming'}"
          :val="data.straight_pitched_conforming"
          @save="save"
      />
      <DetailsCheckbox class="col-lg-4 col-sm-6 col-xs-12"
          :field="{name: 'iso_conforming', label: 'Stock ISO Conforming'}"
          :val="data.iso_conforming"
          @save="save"
      />
    </div>

  </div>

</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import DetailsCheckbox from "@/components/businessObjects/utils/DetailsCheckbox";



export default {
  name: "ConformingSelect",
  components: {
    DetailsCheckbox
  },
  data() {
    return {
      state: store.state,
    }
  },
  props: ['data'],
  methods: {
    save: function (data) {
      store.save(data.field, data.val);
    },
    post: function (data) {
      store.api(data.endPoint, {}, 'object.data');
    },
    changeView: function (view) {
      this.state.currentView = view;
    },
    fieldData: function (field) {
      if (field.displayField) {
        this.$set(field, 'display', this.data[field.displayField]);
      }
      if (field.linkField) {
        this.$set(field, 'linkID', this.data[field.linkField]);
      }
      return field;
    },
  },
  mounted: function () {}
}
</script>

<style scoped>

</style>