<template>
    <div>
        <button class="btn btn-default" @click="save">Save Brands</button>

        <div class="report_options report_options_wide_label spacing_top">
            <div v-for="[id, name] in cache.brandsCache.BRANDS" v-bind:key="id">
                <label>
                    <input type='checkbox' :value="id" v-model="brandsChosen">
                    {{ name }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import brandsCache from "@/cache/brand.cache";
    import { store } from "@/store/BusinessObject.store";

    export default {
        name: "Brands",
        props: ['existingBrands'],
        data() {
            return {
                cache: {
                    brandsCache
                },
                brandsChosen: []
            }
        },
        created() {
            this.brandsChosen = this.existingBrands;
        },
        methods: {
            save: function () {
                let options = {};
                this.brandsChosen.forEach(id => options[`params[${id}]`] = id);
                store.apiWithObjectLoad('save_brands', options);
            }
        }
    }
</script>

<style scoped>

</style>