import partCache from '@/cache/partType.cache.js';
import priceCache from '@/cache/price.cache.js';

export const partStore = {
	state: {
		name: 'part',
		title: 'Part',
		titlePlural: 'Parts',
		nav: {
			base: 'part',
			newView: true,
			views: [
				{
					view: 'details',
					label: 'Details',
				},
				{
					view: 'fastDraw',
					label: 'FastDraw'
				},
				{
					view: 'brands',
					label: 'Brands'
				},
			]
		},
		search: {
			endPoint: 'parts',
			formFields: {
				part: {
					val: '',
					qsField: '[strings][cdi_part_num]',
					label: 'Part',
					component: 'SearchTextInput'
				},
				type: {
					val: 0,
					qsField: '[numbers][ct.cdi_part_type_id]',
					label: 'Type',
					component: 'SearchSelectInput',
					options: partCache.PART_TYPES
				},
				searchIndex: {val: 0, qsField: '[search_index]'}
			},
			resultFields: {
				cdi_part_id: {
					header: 'Part ID',
					link: 'part'
				},
				cdi_part_num: {
					header: 'Part #',
					link: ''
				},
				cdi_part_type: {
					header: 'Type',
					link: ''
				},
				cdi_file_id: {
					header: '2d',
					textField: 'cdi_file_datetime',
					link: 'download',
					hrefId: 'cdi_file_id'
				},
			}
		},
		object: {
			fields: [
				{
					name: 'cdi_part_id',
					label: 'Part ID',
					component: 'DetailsStatic'
				},
				{
					name: 'cdi_part_num',
					label: 'Part #',
					component: 'DetailsStatic',
				},
				{
					name: 'cdi_part_type_id',
					label: 'Type',
					component: 'DetailsSelect',
					options: partCache.PART_TYPES,
				},
				{
					name: 'twod_file_datetime',
					label: '2D',
					linkField: 'twod_file_id',
					component: 'DetailsFile',
				},
				{
					name: 'part_engineering_detail_datetime',
					label: 'Engineering Detail',
					linkField: 'part_engineering_detail_id',
					component: 'DetailsFile',
				},
				{
					name: 'min_tonnage',
					label: 'Min Tonnage',
					component: 'DetailsText',
				},
				{
					name: 'max_tonnage',
					label: 'Max Tonnage',
					component: 'DetailsText',
				},
				{
					name: 'h_straps',
					label: '# of H-Straps',
					component: 'DetailsText',
				},
				{
					name: 'screw_1',
					label: '1" Screws',
					component: 'DetailsText',
				},
				{
					name: 'screw_3',
					label: '3" Screws',
					component: 'DetailsText',
				},
				{
					name: 'num_ER',
					label: '# of ERs',
					component: 'DetailsText',
				},
				{
					name: 'length',
					label: 'Length',
					component: 'DetailsText',
				},
				{
					name: 'width',
					label: 'Width',
					component: 'DetailsText',
				},
				{
					name: 'length_ER',
					label: 'ER Length',
					component: 'DetailsText',
				},
				{
					name: 'height_difference_ER',
					label: 'ER Height Difference',
					component: 'DetailsText',
				},
				{
					name: 'min_height_std',
					label: 'Min Height Std',
					component: 'DetailsText',
				},
				{
					name: 'min_height_pitched',
					label: 'Min Height Pitched',
					component: 'DetailsText',
				},
				{
					name: 'min_height_iso',
					label: 'Min Height Iso',
					component: 'DetailsText',
				},
				{
					name: 'str_curb_screw_qty',
					label: 'Conforming Screw Qty',
					component: 'DetailsText',
				},
				{
					name: 'str_curb_screw_curb_id',
					label: 'Conforming Screw Type',
					component: 'DetailsSelect',
					options: partCache.SCREW_TYPES
				},
				{
					name: 'iso_curb_screw_qty',
					label: 'Iso Screw Qty',
					component: 'DetailsText',
				},
				{
					name: 'iso_curb_screw_curb_id',
					label: 'Iso Screw Type',
					component: 'DetailsSelect',
					options: partCache.SCREW_TYPES
				},
				{
					name: 'knockdown_unavailable',
					label: 'Knockdown Unavailable',
					component: 'DetailsCheckbox'
				},
				{
					name: 'part_price_category_id',
					label: 'Price Category',
					component: 'DetailsSelect',
					options: priceCache.CATEGORIES
				},

			]
		}
	}
}