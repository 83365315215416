<template>
    <div>
        <h1>Parts</h1>
        <form @submit.prevent="save">
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Part #</label>
                    <input type="text" v-model="part" class="form-control">
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Type</label>
                    <select class="form-control" v-model="typeID">
                        <option v-for="[id, type] in cache.partTypeCache.PART_TYPES" v-bind:key="id" :value="id">
                            {{ type }}
                        </option>
                    </select>
                </div>
            </div>
            <button class="btn btn-default">Save Part</button>
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js'
    import partTypeCache from '@/cache/partType.cache.js';

    export default {
        name: "New",
        data() {
            return {
                state: store.state,
                cache: {
                    partTypeCache
                },
                part: '',
                typeID: ''
            }
        },
        created() {
            store.clearObjectData();
        },
        methods: {
            save: function() {
                let options = {
                    'params[cdi_part_num]': this.part,
                    'params[cdi_part_type_id]': this.typeID
                };

                store.api('new_part', options).then(result => {
                    this.$router.push({path: `/${ this.state.nav.base }/${ result.cdi_part_id }`});
                });
            }
        },
    }
</script>

<style scoped>

</style>