export default {
	CATEGORIES: [
		[1, 'AA1'],
		[2, 'AA2'],
		[3, 'A1'],
		[4, 'A2'],
		[5, 'A3'],
		[6, 'B1'],
		[7, 'B2'],
		[8, 'B3'],
		[9, 'C1'],
		[10, 'C2'],
		[11, 'C3'],
		[12, 'C4'],
		[13, 'C5'],
		[14, 'No Price']
	]
};