<template>
    <div>
        <button class="btn btn-default" @click="save">Save Projects</button>

        <div class="report_options report_options_wide_label spacing_top">
            <div v-for="[id, name] in cache.fastDrawCache.PROJECT_TYPES" v-bind:key="id">
                <label>
                    <input type='checkbox' :value="id" v-model="projectsChosen">
                    {{ name }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import fastDrawCache from '@/cache/fastDraw.cache.js';
    import { store } from '@/store/BusinessObject.store.js';

    export default {
        name: "FastDraw",
        props: ['existingProjects'],
        data() {
            return {
                cache: {
                    fastDrawCache
                },
                projectsChosen: []
            }
        },
        created() {
            this.projectsChosen = this.existingProjects;
        },
        methods: {
            save: function () {
                let options = {};
                this.projectsChosen.forEach(id => options[`params[${id}]`] = id);
                store.apiWithObjectLoad('save_projects', options);
            }
        }
    }
</script>

<style scoped>

</style>